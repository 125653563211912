import React from 'react';
import { PageWithAuthorization } from '@homeproved/shared/feature-auth';
import { CompanyReviewPage, prefetch } from '@homeproved/com/feature-company';
import { PageShell } from '@homeproved/com/feature-page-shell';
import { useRouter } from 'next/router';
import {
  apiFactory,
  CompaniesApiFactory,
  Review,
  getDehydratedState,
  ReviewsApiFactory,
  PaginationMeta,
  CompanyData,
} from '@homeproved/shared/data-access';
import { getPathWithLanguage } from '@homeproved/com/feature-localized-routes';

export const Reviews: PageWithAuthorization = ({
  companyData,
  reviews,
  reviewCount,
  googleReviewCount,
  pagination,
}) => {
  const router = useRouter();
  const { slug } = router.query;
  const companyKey = `${(companyData as CompanyData)?.id ?? 'default'}-${slug ?? 'slug'}`;

  return (
    <PageShell tabsMenu>
      <CompanyReviewPage
        key={companyKey}
        reviewCount={reviewCount as number}
        googleReviewCount={googleReviewCount as number}
        paginationData={pagination as PaginationMeta}
        companyData={companyData as CompanyData}
        reviewsData={reviews as Review[]}
        slug={slug as string}
      />
    </PageShell>
  );
};

export async function getServerSideProps({ locale, params: { slug } }) {
  let company = null;
  let reviews = null;
  const api = apiFactory(locale, CompaniesApiFactory);
  await api
    .apiCompaniesCompanyGet(slug)
    .then((result) => {
      company = result;
    })
    .catch((error) => {
      console.log(error);
    });
  const reviewsApi = apiFactory(locale, ReviewsApiFactory);
  await reviewsApi
    .apiCompaniesCompanyReviewsAllGet(company?.data?.data?.id, {
      query: { date: 'desc', rating: '', perPage: 10 },
    })
    .then((result) => {
      reviews = result;
    })
    .catch((error) => {
      console.log(error);
    });

  const getPath = getPathWithLanguage(locale);

  return {
    props: {
      companyData: company ? company.data.data : null,
      reviews: reviews ? reviews.data?.data : null,
      pagination: reviews ? reviews.data?.meta : null,
      reviewCount: company ? company.data?.data?.reviewsCount : 0,
      googleReviewCount: company ? company.data?.data?.googleReviewsCount : 0,
      dehydratedState: await getDehydratedState(prefetch.reviews(locale, slug)),
      ogImage: company?.data?.data?.lastSocialShare?.data?.original || '',
      url:
        process.env.NEXT_PUBLIC_COM_URL +
        getPath('/company/:slug/reviews', {
          slug,
        }),
      meta: {
        title: 'meta.titles.companyReviews',
        description: 'meta.descriptions.companyReviews',
        variables: {
          '%COMPANY%': company?.data?.data?.name ?? '',
          '%LOCALITY%': company?.data?.data?.city ?? '',
        },
      },
    },
  };
}

export default Reviews;
